<template>
    <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.50043 9.99979H4.00043M3.91577 10.2913L1.58085 17.266C1.39742 17.8139 1.3057 18.0879 1.37152 18.2566C1.42868 18.4031 1.55144 18.5142 1.70292 18.5565C1.87736 18.6052 2.14083 18.4866 2.66776 18.2495L19.3792 10.7293C19.8936 10.4979 20.1507 10.3822 20.2302 10.2214C20.2993 10.0817 20.2993 9.91785 20.2302 9.77819C20.1507 9.61743 19.8936 9.5017 19.3792 9.27026L2.66193 1.74751C2.13659 1.51111 1.87392 1.39291 1.69966 1.4414C1.54832 1.48351 1.42556 1.59429 1.36821 1.74054C1.30216 1.90893 1.3929 2.18231 1.57437 2.72906L3.91642 9.78532C3.94759 9.87923 3.96317 9.92618 3.96933 9.9742C3.97479 10.0168 3.97473 10.0599 3.96916 10.1025C3.96289 10.1506 3.94718 10.1975 3.91577 10.2913Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'WidgetInvite'
}
</script>
<style scoped lang='scss'>
svg {
    width: 22px;
    height: 22px;
}
svg path {
    stroke: $white;
}
</style>
