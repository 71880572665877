<template>
    <div>
        <div class="invite_widget">
            <div class="invite_widget--head">
                <p class="invite-heading">
                    <span class="invite-heading__title">Share & Earn</span>
                    <span
                        v-if="$route.name !== 'friends_about'"
                        class="invite-heading__link"
                        @click="$router.push({ name: 'friends_about' })"
                    >
                        Learn more
                    </span>
                </p>
                <span
                    v-if="showArrow"
                    class="invite_widget--toggle"
                    @click="slideWidget"
                >
                    <chevron-up-icon v-if="isWidgetOpen" />
                    <chevron-down-icon v-else />
                </span>
                <span class="invite_widget--label" />
            </div>
            <div v-if="isWidgetOpen">
                <div class="invite_methods">
                    <invite-link />
                    <InviteEmailForm />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronUpIcon from 'mdi-vue/ChevronUp'
import ChevronDownIcon from 'mdi-vue/ChevronDown'

import InviteLink from './InviteLink.vue'
import InviteEmailForm from './InviteEmailForm.vue'

export default {
    components: {
        InviteLink,
        InviteEmailForm,
        ChevronUpIcon,
        ChevronDownIcon
    },
    data() {
        return {
            showArrow: true,
            isWidgetOpen: 1
        }
    },
    async created() {
        if (localStorage.closedInviteWidget) {
            this.isWidgetOpen = await this.getWidgetChevronState()
        }
    },
    methods: {
        async getWidgetChevronState() {
            return Number(await localStorage.getItem('closedInviteWidget'))
        },
        setWidgetChevronState(state) {
            localStorage.setItem('closedInviteWidget', state)
        },
        async slideWidget() {
            this.isWidgetOpen = Number(!this.isWidgetOpen)
            this.setWidgetChevronState(this.isWidgetOpen)
        }
    }
}
</script>
