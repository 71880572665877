import { render, staticRenderFns } from "./WidgetCopy.vue?vue&type=template&id=03d3a013&scoped=true"
import script from "./WidgetCopy.vue?vue&type=script&lang=js"
export * from "./WidgetCopy.vue?vue&type=script&lang=js"
import style0 from "./WidgetCopy.vue?vue&type=style&index=0&id=03d3a013&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d3a013",
  null
  
)

export default component.exports